.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.switch-inner {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.switch-yes {
  left: 2px;
  transform: translateX(0);
}

.switch-no {
  right: 2px;
  transform: translateX(100%);
}
