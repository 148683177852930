.unique-button {
    background: linear-gradient(135deg, #ff4081, #2196f3, #ffeb3b, #ff4081);
    background-size: 400%;
    color: #fff;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  .unique-button .button-text {
    position: relative;
    z-index: 1;
  }
  
  .unique-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-30deg);
    transition: all 0.5s ease;
  }
  
  .unique-button:hover::before {
    left: 125%;
  }
  
  .unique-button:hover {
    background-position: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 0 20px rgba(255, 64, 129, 0.6);
  }
  
  .unique-button:active {
    transform: scale(0.95);
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .unique-button {
    animation: gradientAnimation 5s ease infinite;
  }
  .serious-button {
    background: linear-gradient(135deg, #2f2f2f, #1a1a1a);
    color: #f5f5f5;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .serious-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #3498db, #2ecc71);
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }
  
  .serious-button:hover::before {
    opacity: 0.1;
  }
  
  .serious-button:active {
    transform: scale(0.97);
  }
  
  .serious-button:focus-visible {
    border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.6);
  }
  