/* Estilo personalizado para el scroll */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f0f0f0; /* Color de fondo del track */
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #c0c0c0; /* Color del pulgar del scroll */
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; /* Color al pasar el mouse por el scroll */
  }
  
  /* Scroll estándar para otros navegadores */
  .custom-scrollbar {
    scrollbar-width: thin; /* Ancho del scroll */
    scrollbar-color: #c0c0c0 #f0f0f0; /* Pulgar y track */
  }
  